import React, { Component } from "react"
import FormMaker from "../../../components/formMaker"
import { Steps } from "intro.js-react"
import IntroWrapper from '../../../components/client-only-wrapper';

export default class EventFlyerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          element: "#brief",
          title: "Event Type",
          intro: "Please write out all the copy that explains the event.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#selectfiles",
          title: "Event Speakers",
          intro: "Please do load the photographs of your speaker(s) here.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".fileupload",
          title: "Upload File",
          intro:
            "Please do supply any images you would like to have included on your flyer and any examples of  precious flyers or inspiration",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#eventlink",
          title: "Event Link",
          intro:
            "Don't forget the meeting link so that your guests can attend.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#addevent",
          title: "Add Days",
          intro:
            "If you have more than 1 day to the event you can click here to add it.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".submitLater",
          title: "NOTE:",
          intro:
            "You can save your progress here. Just remember that for it to be sent to head office you have to select the 'submit form' button",
          position: "top",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }
  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    console.log(this.props)
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>

        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>

        <FormMaker
          formId="60ca93a3b38ee0282868e461"
          formType="virtual-event-promotion-flyers"
          //   id={`${"brief"} ${"selectfiles"} ${"eventlink"} ${"addevent"}`}
          submitLater="submitLater"
          fileupload="fileupload"
        />
      </div>
    )
  }
}
